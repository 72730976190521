import React, { Component } from 'react'
import OAuth from './OAuth'
import DoorButtons from './DoorButtons'
import MenuButton from './MenuButton'
import Menu from './Menu'

export default class Container extends Component {

    state = {
        user: {},
        menuOpen: false
    }

    componentDidMount() {
        const { socket } = this.props
        socket.on('login', user => {  
        this.setState({user})
        })
    }

    handleAuthentication(user) {
        this.setState({user})
    }

    isAuthenticated() {
        return Boolean(this.state.user.name)
    }

    isAuthorized() {
        return this.isAuthenticated() && this.state.user.authorized
    }

    isAdmin() {
        return this.isAuthorized() && this.state.user.admin
    }


  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
  }

  handleLinkClick() {
    this.setState({menuOpen: false});
  }

  render(){
    const styles=
      {
        container:{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: '99',
          opacity: 0.9,
          display:'flex',
          alignItems:'center',
          background: 'black',
          width: '100%',
          color: 'white',
          fontFamily:'Lobster',
        },
        logo: {
          margin: '0 auto',
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          filter: this.state.menuOpen ? 'blur(2px)':null,
          transition: 'filter 0.5s ease',
        },
      }

    const {socket} = this.props

    return (
      <div>
        <div style={styles.container}>
          <MenuButton open={this.state.menuOpen} onClick={()=>this.handleMenuClick()} color='white'/>
        </div>
        <Menu open={this.state.menuOpen}>
            <OAuth 
                user={this.state.user}
                handler={this.handleAuthentication.bind(this)}
                provider={'google'}
                socket={socket}
            />
        { this.isAuthorized() &&
            <DoorButtons socket={socket} />
        }
        { this.isAuthenticated() && !this.isAuthorized() &&
            <div> You are not authorized to do anything - contact us </div>
        }
        </Menu>
      </div>
    )
  }
}
