import React, {Component} from 'react'
import './DoorButtons.css'
import { API_URL, TOKEN_URL } from './config'

function fetchWithTimeout(url, options, timeout = 7000) {
    const f = fetch(url, options)
    return Promise.race([
        f,
        new Promise((_, reject) =>
            setTimeout(() => {
              f.catch((error) => {})
              reject(new Error('timeout'))}
              , timeout))
    ])
}

export default class DoorButtons extends Component {
    state = {
        up_requested: false,
        up_active: false,
        down_requested: false,
        down_active: false,
        on_wifi: true,
        error_fetching: false
    }
    
    componentDidMount() {
        const { socket } = this.props

        socket.on('upstairs-active', () => {
            this.setState({up_active: true})
        })

        socket.on('downstairs-active', () => {
            this.setState({down_active: true})
        })

        socket.on('upstairs-stopped', () => {
            this.setState({up_active: false, up_requested: false})
        })

        socket.on('downstairs-stopped', () => {
            this.setState({down_active: false, down_requested: false})
        })
    }

    openUpstairs() {
        this.setState({'up_requested': true})
        fetchWithTimeout(`${TOKEN_URL}`, {}, 3000)
          .then( (res) => { 
            return res.json()
          })
          .then( (token) => {
              token = token.token
              this.setState({'on_wifi': true, 'error_fetching': false})
              const fetch_settings = {
                credentials: 'include',
                body: JSON.stringify({token: token}),
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                }
              }
              fetch(`${API_URL}/up`,fetch_settings)
                  .catch( (error) => this.setState({'error_fetching': true}))
          })
          .catch( (error) => this.setState({'on_wifi': false, 'up_requested': false}))
    }

    openDownstairs() {
        this.setState({'down_requested': true})
        fetchWithTimeout(`${TOKEN_URL}`, {}, 3000)
          .then( (res) => { 
            return res.json()
          })
          .then( (token) => {
              token = token.token
              this.setState({'on_wifi': true, 'error_fetching': false})
              const fetch_settings = {
                credentials: 'include',
                body: JSON.stringify({token: token}),
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                }
              }
              fetch(`${API_URL}/down`,fetch_settings)
                  .catch( (error) => this.setState({'error_fetching': true}))
          })
          .catch( (error) => this.setState({'on_wifi': false, 'down_requested': false}))
    }

    generateText(door, requested, active){
        if (active) {
            return `${door} opening`
        }
        if (requested) {
            return `${door} requested`
        }
        return `Open ${door.toLowerCase()} door`
    }

    upText(){
        return this.generateText('Upstairs', this.state.up_requested, this.state.up_active)
    }

    downText(){
        return this.generateText('Downstairs', this.state.down_requested, this.state.down_active)
    }

    render() {
        const disabled = this.state.disabled ? 'disabled' : ''
        const upActive = this.state.up_active ? 'active' : ''
        const downActive = this.state.down_active ? 'active' : ''
        const upRequested = this.state.up_requested ? 'requested' : ''
        const downRequested = this.state.down_requested ? 'requested' : ''
        return (
            <div>
            <div className={`${disabled} doorbuttons`}>
                <div className={'button-wrapper upstairs'}>
                    <button 
                        onClick={this.openUpstairs.bind(this)}
                        className={`button upstairs ${upActive} ${upRequested}`}
                    >
                   {this.upText()}
                    </button>
                </div>
                <div className={'button-wrapper downstairs'}>
                    <button
                        onClick={this.openDownstairs.bind(this)}
                        className={`button downstairs ${downActive} ${downRequested}`}
                    >
                    {this.downText()}
                    </button>
                </div>
            </div>
            { !this.state.on_wifi &&
            <div className={'wifi-warning warning'}>
                Not on wifi - door will not work
            </div>
            }
            { this.state.error_fetching &&
            <div className={'fetch-error warning'}>
                    Error requesting door open
            </div>
            }
            </div>
        )
    }
}
