const all_config = {
    development: {
        SOCKET_URL:"https://localhost:8080",
        API_URL:"https://localhost:8080/api/v1",
        TOKEN_URL:"https://door.lefco.work/token"
    },
    production: {
        SOCKET_URL:"https://lefco.work",
        API_URL:"https://lefco.work/api/v1",
        TOKEN_URL:"https://door.lefco.work/token"
    }
}

let config
if (process.env.NODE_ENV==='production') {
    config = all_config["production"]
} else {
    config = all_config["development"]
}

export const SOCKET_URL=config['SOCKET_URL']
export const API_URL=config['API_URL']
export const TOKEN_URL=config['TOKEN_URL']


