import React, { Component } from 'react'
import io from 'socket.io-client'
import Container from './Container'
import { SOCKET_URL, API_URL } from './config'
import './App.css'

let connected = false
const socket = io(SOCKET_URL)
socket.on('connect', () => {
      connected = true
})

export default class App extends Component {

  state = { connected: false,
  menuOpen: false}
          
  setConnected(value) {
    this.setState({connected: value})
  }

  componentDidMount() {
    if(connected){
    fetch(`${API_URL}/init?socketId=${socket.id}`,
      {credentials:'include'})
    } else {
      socket.on('connect', () => {
        fetch(`${API_URL}/init?socketId=${socket.id}`,
          {credentials:'include'})
      })
    }
  }




  render () {
    return (
        <div className="App">
            <Container socket={socket} />
        </div>
    );
  }
}

